(function () {
    'use strict';

    angular.module('aerosApp')
        .factory('UserProfileService', UserProfileService);

    UserProfileService.$inject = ['$q', 'aerosApi', 'SessionService'];

    function UserProfileService($q, aerosApi, SessionService) {

        var service = {
            userProfile:undefined,
            get: get,
            fetch: fetch,
            set: set
        };

        function fetch(orgId, force) {
            var sessionInfo = SessionService.getSessionInfo();

            if (sessionInfo && !force) {
                return $q.resolve(sessionInfo);
            }

            if (orgId) {
                return aerosApi.loadOrganizationUserInfo(orgId)
                    .then(function (results) {
                        set(results.data);
                        return SessionService.getSessionInfo();
                    });
            } else {
                return $q.reject();
            }

        }

        function get() {
            return SessionService.getSessionInfo();
        }

        function set(data) {
            var sessionInfo = SessionService.getSessionInfo();

            if (sessionInfo.token && !data.token) {
                data.token = sessionInfo.token;
            }

            if (sessionInfo.appAccess && !data.appAccess) {
                data.appAccess = sessionInfo.appAccess;
            }

            if (sessionInfo.tokenExpires && !data.tokenExpires) {
                data.tokenExpires = sessionInfo.tokenExpires;
            }

            SessionService.setSessionInfo(data);
        }

        return service;
    }
})();
